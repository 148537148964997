<template>
  <div>
    <Card :bordered="false" dis-hover class="ivu-mt">
      <Form
        ref="searchWhere"
        :model="searchWhere"
        :label-width="80"
        label-position="right"
        class="tabform"
        @submit.native.prevent>
        <Row :gutter="24" type="flex">
          <Col span="24">
            <FormItem label="语言分类：">
              <RadioGroup type="button" v-model="searchWhere.is_admin" class="mr15" @on-change="handleSelectChange">
                <Radio :label="item.value" v-for="(item, index) in langType.isAdmin" :key="index">{{ item.title }}</Radio>
              </RadioGroup>
            </FormItem>
          </Col>
          <Col span="24">
            <FormItem label="语言类型：">
              <RadioGroup type="button" v-model="searchWhere.type_id" class="mr15" @on-change="handleSelectChange">
                <Radio :label="item.value" v-for="(item, index) in langType.langType" :key="index">{{ item.title }}</Radio>
              </RadioGroup>
            </FormItem>
          </Col>
          <Col span="24">
            <FormItem label="搜索：">
              <div class="acea-row row-middle">
                <Input
                  search
                  enter-button
                  @on-search="handleSelectChange"
                  placeholder="请输入语言备注"
                  element-id="name"
                  v-model="searchWhere.remarks"
                  style="width: 30%"/>
              </div>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </Card>
    <Alert class="l-m-t10" :closable="true">
      使用说明
      <template slot="desc" >
        1、前端页面：添加用户端页面语言，添加完成之后状态码为中文文字，前端页面使用 $t(`xxxx`)，js文件中使用 this.t(`xxxx`) 或者使用
        that.t(`xxxx`)<br />
        2、后端接口：添加后端接口语言，添加完成之后状态码为6位数字，后台抛错或者控制器返回文字的时候直接填写状态码数字
      </template>
    </Alert>
    <Card :bordered="false" dis-hover>
      <Row type="flex" class="mb15">
        <Col>
          <Button type="primary" icon="md-add" @click="handleCreate">添加语句</Button>
        </Col>
      </Row>
      <Table
        ref="table"
        :columns="columns"
        :data="langCodeList"
        class="l-m-t25"
        :loading="loading"
        no-data-text="暂无数据"
        no-filtered-data-text="暂无筛选结果">
        <template slot-scope="{ row, index }" slot="action">
          <a @click="handleEdit(row)">编辑</a>
          <Divider type="vertical" />
          <a class="l-color-error" @click="handleDelete(row, '删除语言', index)">删除</a>
        </template>
      </Table>
      <div class="acea-row row-right l-m-t25">
        <Page :total="total" :current="searchWhere.page" show-elevator show-total @on-change="handlePageChange" :page-size="searchWhere.limit"/>
      </div>
    </Card>

    <!-- 添加语言 -->
    <Modal
      v-model="createLangModal"
      width="750"
      title="添加需要翻译的语句"
      :loading="formLoading"
      @on-ok="handleOk"
      @on-cancel="createLangModal = false"
      @on-visible-change="handleModalChange">
      <Form ref="langFormData" :model="langFormData" :rules="ruleValidate" >
        <FormItem label="应用端：" class="mb20" :label-width="120">
          <RadioGroup type="button" v-model="langFormData.is_admin" class="mr15">
            <Radio :label="item.value" v-for="(item, index) in langType.isAdmin" :key="index">{{ item.title }}</Radio>
          </RadioGroup>
        </FormItem>
        <Input v-model="langFormData.edit" v-show="false"></Input>
        <FormItem label="需要翻译的语句：" prop="remarks" class="mb20">
          <Input
            v-model="langFormData.remarks"
            placeholder="请输入需要添加翻译的语句"
            style="width: 330px"
            search
            enter-button="翻译"
            @on-search="translate"></Input>
        </FormItem>
        <FormItem prop="remark" class="mb20">
          <Table
            ref="langTable"
            :loading="traTabLoading"
            :columns="langColumns"
            :data="langFormData.list"
            no-data-text="暂无数据"
            no-filtered-data-text="暂无筛选结果">
            <template slot-scope="{ row, index }" slot="lang_explain">
              <Input v-model="langFormData.list[index].lang_explain" class="priceBox"></Input>
            </template>
          </Table>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
import mixins from '@/mixins'
import {
  getLangCodeListApi,
  getLangCodeInfoApi,
  saveLangCodeApi,
  getLangCodeTranslateApi
} from '@/api/system'

export default {
  name: 'list',
  mixins: [mixins],
  data () {
    return {
      // 表单数据
      searchWhere: {
        is_admin: 0,
        type_id: 1,
        remarks: '',
        page: 1,
        limit: 20
      },
      // 语言类型
      langType: {},
      // 表格数据
      langCodeList: [],
      columns: [
        {
          title: '编号',
          key: 'id',
          width: 80
        },
        {
          title: '原语句',
          key: 'remarks',
          minWidth: 250
        },
        {
          title: '对应语言翻译',
          key: 'lang_explain',
          minWidth: 250
        },
        {
          title: '状态码/文字(接口/页面调用参考)',
          key: 'code',
          minWidth: 100
        },
        {
          title: '语言类型',
          key: 'language_name',
          minWidth: 100
        },
        {
          title: '操作',
          slot: 'action',
          fixed: 'right',
          width: 120
        }
      ],

      // 添加弹窗
      createLangModal: false,
      formLoading: true,
      traTabLoading: false,

      // 添加表单数据
      langFormData: {
        is_admin: 0,
        code: '',
        remarks: '',
        edit: 0,
        list: []
      },
      ruleValidate: {
        code: [{ required: true, message: '请输入状态码/文字', trigger: 'blur' }],
        remarks: [{ required: true, message: '请输入文字', trigger: 'blur' }]
      },

      langColumns: [
        {
          title: '语言类型',
          key: 'language_name',
          width: 120
        },
        {
          title: '对应语言翻译',
          slot: 'lang_explain',
          minWidth: 250
        }
      ]
    }
  },
  mounted () {
    this.getLangCodeListRequest()
  },
  methods: {
    // 获取语言列表
    getLangCodeListRequest () {
      getLangCodeListApi(this.searchWhere).then(res => {
        this.loading = false
        this.langCodeList = res.data.list
        this.total = res.data.count
        this.langType = res.data.langType
      }).catch((res) => {
        this.loading = false
        this.$Message.error(res.msg)
      })
    },
    // 处理搜索
    handleSelectChange () {
      this.searchWhere.page = 1
      this.getLangCodeListRequest()
    },
    translate () {
      if (!this.langFormData.remarks.trim()) {
        return this.$Message.warning('请先输入翻译内容')
      }
      this.traTabLoading = true
      getLangCodeTranslateApi({ text: this.langFormData.remarks }).then((res) => {
        this.langFormData.list.map((e) => {
          e.lang_explain = res.data[e.type_id]
        })
        this.traTabLoading = false
      }).catch((err) => {
        this.traTabLoading = false
        this.$Message.error(err.msg)
      })
    },
    // 语言添加
    handleCreate () {
      this.langFormData.list = this.langType.langType.map((e) => {
        return {
          language_name: e.title,
          lang_explain: '',
          remarks: '',
          type_id: e.value
        }
      })
      this.createLangModal = true
    },
    handleOk () {
      if (!this.langFormData.remarks.trim()) {
        this.formLoading = false
        this.$nextTick(() => {
          this.formLoading = true
        })
        return this.$Message.error('请先输入语言说明')
      }
      saveLangCodeApi(this.langFormData).then((res) => {
        this.createLangModal = false
        this.$Message.success(res.msg)
        this.getLangCodeListRequest()
      }).catch((err) => {
        this.formLoading = false
        this.$nextTick(() => {
          this.formLoading = true
        })
        this.$Message.error(err.msg)
      })
    },
    handleModalChange () {},
    // 语言编辑
    handleEdit (row) {
      this.langFormData.is_admin = this.searchWhere.is_admin
      this.code = row.code
      getLangCodeInfoApi({ code: row.code }).then((res) => {
        this.langFormData.list = res.data.list
        this.langFormData.code = res.data.code
        this.langFormData.remarks = res.data.remarks
        this.langFormData.edit = 1
        this.createLangModal = true
      }).catch((err) => {
        this.loading = false
        this.$Message.error(err.msg)
      })
    },
    // 处理语言删除
    handleDelete (row, tit, num) {
      let delfromData = {
        title: tit,
        num: num,
        url: `setting/lang/code/del/${row.id}`,
        method: 'DELETE',
        ids: ''
      }
      this.$modalSure(delfromData).then((res) => {
        this.$Message.success(res.msg)
        this.tabList.splice(num, 1)
      }).catch((res) => {
        this.$Message.error(res.msg)
      })
    },
    // 分页搜索
    handlePageChange () {}
  }
}
</script>

<style scoped>

</style>
